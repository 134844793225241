export const queryKeys = {
	cart: 'medusa-cart',
	cartProducts: 'medusa-cart-products',
	categories: 'medusa-categories',
	featuredCollections: 'medusa-featured-collections',
	featuredProducts: 'medusa-featured-products',
	order: 'medusa-order',
	paymentProviders: 'medusa-cart-payment-providers',
	paymentSessions: 'medusa-cart-payment-sessions',
	products: 'medusa-products',
	region: 'medusa-region',
	regions: 'medusa-regions',
	searchProducts: 'medusa-search-products',
	shippingOptions: 'medusa-cart-shipping-options',
	singleProduct: 'medusa-single-product',
};
