import type { PaymentProvider, StoreCartsRes, StoreCompleteCartRes } from '@medusajs/medusa';
import type { StorePostCartsCartReq } from '@medusajs/medusa';
import type { PricedShippingOption } from '@medusajs/medusa/dist/types/pricing';
import config from '@src/lib/config';
import { useRegion } from '@src/lib/medusa/hooks/use-region';
import { medusaClient as medusa } from '@src/lib/medusa/medusa-client';
import { queryKeys } from '@src/lib/react-query/query-keys';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSessionStorage } from 'usehooks-ts';

export type Cart = StoreCartsRes['cart'];

export interface AddLineItemOptions {
	quantity: number;
	variantId: string;
}

export interface UpdateLineItemOptions {
	quantity: number;
	lineItemId: string;
}

export interface RemoveLineItemOptions {
	lineItemId: string;
}

export interface CompleteOrderArgs {
	paymentProvider: string;
}

export interface UseCartResult {
	cart?: Cart;
	currency_code: string;
	resetCart: () => void;
	updateCart: (options: StorePostCartsCartReq) => Promise<Cart>;
	shippingMethods?: PricedShippingOption[];
	paymentProviders?: PaymentProvider[];
	addLineItem: (options: AddLineItemOptions) => Promise<Cart>;
	updateLineItem: (options: UpdateLineItemOptions) => Promise<Cart>;
	removeLineItem: (options: RemoveLineItemOptions) => Promise<Cart>;
	addShippingMethod: (id: string) => Promise<void>;
	setPaymentSession: UseMutateAsyncFunction<Cart, unknown, string>;
	completeOrder: UseMutateAsyncFunction<StoreCompleteCartRes, unknown, CompleteOrderArgs>;
}

function throwIfCartUnavailable(cart?: Cart) {
	if (!cart?.id) throw new Error('Cart not available');
}

/**
 * A hook to manage the user's cart
 *
 * @remarks
 * In addition to exposing the current cart, this hook returns methods for
 * adding/updating/removing line items, changing shipping and payment methods,
 * and converting the cart to an order.
 *
 * @returns The current cart and associated methods
 */
export function useCart(): UseCartResult {
	const [cartId, setCartId] = useSessionStorage<string>('medusa-cart-id', '');
	const [, setOrderId] = useSessionStorage<string>('medusa-order-id', '');
	const queryClient = useQueryClient();
	const { region } = useRegion();

	/**
	 * The current cart
	 *
	 * @remarks
	 * Any page that needs to work with the user's cart should call the useCart
	 * hook and reference this value and the associated methods.
	 */
	const cart = useQuery<Cart>(
		[queryKeys.cart, cartId], //
		async () => {
			if (cartId) {
				try {
					// Get the existing cart if possible
					const { cart } = await medusa.carts.retrieve(cartId);
					await queryClient.invalidateQueries([queryKeys.paymentSessions]);
					await queryClient.invalidateQueries([queryKeys.shippingOptions]);
					return cart;
				} catch (e) {
					// Log error but fallback to create cart
					console.error('Error getting cart', e);
				}
			}

			// Create cart if no cart id is found
			const { cart } = await medusa.carts.create({
				region_id: region || config.defaultRegion,
				sales_channel_id: config.defaultSalesChannel,
			});

			await queryClient.invalidateQueries([queryKeys.paymentSessions]);
			await queryClient.invalidateQueries([queryKeys.shippingOptions]);
			setCartId(cart.id);

			return cart;
		},
	);

	/**
	 * A list of payment providers for the current cart
	 */
	const paymentProviders = useQuery<PaymentProvider[]>(
		[queryKeys.paymentProviders, cartId],
		async () => {
			// Payment providers are specific to each region
			const { region } = await medusa.regions.retrieve(cart.data?.region_id || '');

			if (!region) {
				throw new Error('Region not found');
			}

			return region.payment_providers;
		},
		{
			// Do not populate until a shipping address is set
			enabled: !!cart?.data?.shipping_address,
			placeholderData: [],
		},
	);

	/**
	 * A list of shipping methods for the current cart
	 */
	const shippingMethods = useQuery<PricedShippingOption[]>(
		[queryKeys.shippingOptions, cartId, JSON.stringify(cart)], //
		async () => {
			if (!cartId) return [];
			const { shipping_options } = await medusa.shippingOptions.listCartOptions(cartId || '');
			return shipping_options || [];
		},
		{
			// Do not populate until a shipping address is set
			enabled: !!cart?.data?.shipping_address,
			placeholderData: [],
		},
	);

	/**
	 * Abandon the current cart
	 *
	 * @remarks
	 * Any items in the cart will be lost. This will also clear any shipping
	 * methods or payment sessions associated with the cart.
	 */
	const resetCart = useCallback(async () => {
		setCartId('');
		await queryClient.invalidateQueries([queryKeys.cart]);
	}, [queryClient, setCartId]);

	/**
	 * Set the payment session for the cart
	 *
	 * @remarks
	 * If the cart does not have a payment session, one will be created.
	 *
	 * @param provider_id - The selected payment provider id
	 */
	const setPaymentSession = useMutation(
		[cart?.data?.payment_sessions, cart.data?.updated_at, cartId], //
		async (provider_id: string) => {
			if (!cart?.data?.id) {
				throw new Error('Cart not available.');
			}

			let myCart = cart.data;

			// If no payment sessions exist, create one for each available
			// provider. Store the result of this in the local cart variable.
			if (!cart.data.payment_sessions || cart.data.payment_sessions.length === 0) {
				({ cart: myCart } = await medusa.carts.createPaymentSessions(cart.data.id));
			}

			// If the provider is not found, throw an error
			const session = myCart.payment_sessions.find((s) => {
				return s.provider_id === provider_id;
			});

			if (!session) {
				throw new Error(`Session for provider ${provider_id} not found.`);
			}

			// Set the payment session to the selected provider session
			const res = await medusa.carts.setPaymentSession(myCart.id, {
				provider_id: provider_id,
			});

			// Invalidate the cache
			queryClient.setQueryData(
				[queryKeys.cart, cartId],
				res.cart, //
			);

			return res.cart;
		},
	);

	/**
	 * Add a line item to the cart
	 */
	const addLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: AddLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { cart: newCart } = await medusa.carts.lineItems //
				.create(cart.data!.id, {
					quantity: options.quantity,
					variant_id: options.variantId,
				});

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId], //
				newCart,
			);

			return newCart;
		},
	);

	/**
	 * Update a line item in the cart
	 */
	const updateLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: UpdateLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { lineItemId } = options;
			const { cart: newCart } = await medusa.carts.lineItems //
				.update(cart.data!.id, lineItemId, {
					quantity: options.quantity,
				});

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	/**
	 * Remove a line item from the cart
	 */
	const removeLineItem = useMutation(
		[cart.data?.updated_at, cartId], //
		async (options: RemoveLineItemOptions) => {
			throwIfCartUnavailable(cart.data);

			const { lineItemId } = options;
			const { cart: newCart } = await medusa.carts.lineItems //
				.delete(cart.data!.id, lineItemId);

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	/**
	 * Update the cart with the supplied data
	 */
	const updateCart = useMutation(
		[cart.data?.updated_at, cartId], //
		async (data: StorePostCartsCartReq) => {
			throwIfCartUnavailable(cart.data);

			const { cart: newCart } = await medusa.carts //
				.update(cartId, data);

			queryClient.setQueryData<Cart>(
				[queryKeys.cart, cartId],
				newCart, //
			);

			return newCart;
		},
	);

	/**
	 * Add a shipping method to the cart
	 *
	 * @remarks
	 * The user should be presented a choice of shipping methods avialable. When
	 * the user selects a method, this should be called to set that method for
	 * the current cart.
	 */
	const addShipping = useMutation(
		[cart.data?.updated_at, cartId], //
		async (id: string) => {
			throwIfCartUnavailable(cart.data);

			const opts = { option_id: id };
			await medusa.carts.addShippingMethod(cart.data!.id, opts);
			await queryClient.invalidateQueries([queryKeys.cart]);
		},
	);

	/**
	 * Convert the cart to a completed order.
	 *
	 * @remarks
	 * This will set the payment session for the cart and complete the order. If
	 * the payment provider requires more information, the result type may be
	 * 'cart' instead of 'order'. The caller should handle the response
	 * accordingly to redirect the user to a payment page, for example.
	 */
	const completeOrder = useMutation([cart.data?.updated_at, cartId], async ({ paymentProvider }: CompleteOrderArgs) => {
		throwIfCartUnavailable(cart.data);

		const opts = { provider_id: paymentProvider };
		await medusa.carts.setPaymentSession(cart.data!.id, opts);
		const result = await medusa.carts.complete(cart.data!.id);

		if (result.type === 'order') {
			setOrderId(result.data.id);
		}

		await queryClient.invalidateQueries([queryKeys.cart]);
		return result as StoreCompleteCartRes;
	});

	return {
		cart: cart.data,
		currency_code: cart.data?.region.currency_code.toUpperCase() || 'USD',
		resetCart,
		updateCart: updateCart.mutateAsync,
		shippingMethods: shippingMethods.data,
		paymentProviders: paymentProviders.data,
		addLineItem: addLineItem.mutateAsync,
		updateLineItem: updateLineItem.mutateAsync,
		removeLineItem: removeLineItem.mutateAsync,
		addShippingMethod: addShipping.mutateAsync,
		setPaymentSession: setPaymentSession.mutateAsync,
		completeOrder: completeOrder.mutateAsync,
	};
}
